@media print {
  button {
    display: none!important;
  }
  .root {
    display: block!important;
  }
  .content {
    padding-left: 0!important;
    page-break-inside: initial;
    .liftResults {
      background-color: transparent!important;
      box-shadow: none!important;
    }
    .liftsList {
      max-width: 900px;
    }
  }
  .title {
    align-items: flex-end;
    display: block!important;;

    clear: both;
    min-height: 40px;

    h4:first-child {
      font-size: 2rem!important;
      text-decoration: underline;
      text-underline-offset: 4px;

      display: inline-block;
    }
    h4:nth-child(2) {
      font-size: 1.3rem!important;
      background-color: var( --liftstatus-primary-color-dark);
      border: 6px solid var( --liftstatus-primary-color-dark);
      padding: 0 2px;
      color: white!important;
      box-sizing: border-box;
      display: inline-block;
      min-width: 160px;

      float: right;

      display: flex;
      justify-content: space-between;

    }
   }
  .card {
    border: 1px solid whitesmoke;
    border-bottom: none;
    padding: 4px!important;
    &:last-child {
      border-bottom: 1px solid whitesmoke;
    }
    .cardContainer {
      .lifticon {
        visibility: hidden;
      }
    }
  }
}

.root {
  display: flex;
}

.content {
  width: 100%;
  position: relative;
  z-index: 0;
  padding-left: 16px;

  .liftsList {
    padding-top: 16px;
    max-width: 800px;
  }

  .liftResults {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  .title {
    width: 100%;
    justify-content: space-between;
    page-break-inside: avoid;

    display: block!important;;
    clear: both;
    min-height: 40px;

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      color: var(--liftstatus-grey);
      display: inline-block;
    }

    h4:nth-child(2) {
      display: inline-block;
      min-width: 160px;

      float: right;

      display: flex;
      justify-content: space-between;
    }
  }
}

.periods {
  grid-column: 1/5;
}

.card {
  grid-column: 1/5;
  padding: 16px;
  cursor: pointer;
  page-break-inside: avoid;

  .cardContainer {
    display: grid;
    grid-template: auto / 1fr 98%;
    grid-column-gap: 6px;

    .lifticon {
      grid-column: 1/2;
      grid-row: 1/3;
      align-self: center;
      justify-self: center;
      color: var(--liftstatus-grey);
    }

    .cardTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h4:nth-child(1) {
        padding-right: 6px;
      }

      .statsTypeValueText {
        padding: 0 8px 0 6px;
        white-space: nowrap;
      }
    }

    p {
      color: var(--liftstatus-grey);
      margin: 0;
      grid-column: 2/3;
      align-self: center;
      padding-right: 16px;
    }

    h4 {
      margin: 0;
      align-self: center;
      font-weight: 500;
    }
  }
}

.card:hover {
  background-color: whitesmoke;
}

.formControl {
  width: 189px;
}

.pagination {
  grid-column: 1/5;
  justify-self: center;
  padding: 6px;
}

.progressIndicator {
  grid-column: 1/5;
  justify-self: center;
}
