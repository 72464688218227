.stickyHeader {
  position: fixed;
  width: calc(100% - 220px);
  top: 0;
  left: 0;
  height: 70px;
  background-color: white !important;
  z-index: 1000;
  box-shadow: 0 3px 6px #00000017, 0 3px 6px rgba(0, 0, 0, 0.13);
  // margin-left: -16px;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  z-index: 9;
  margin-left: 220px;

  .headerPeriods {
    width: 100%;
  }

  .headerLiftData {
    margin-top: -12px;
    margin-left: 16px;
    width: 100%;
    min-width: 500px;
  }
}


.deleteLiftIcon {
  color: var(--liftstatus-grey);

  :hover {
    color: var(--liftstatus-red);
    cursor: pointer;
  }
}