

.recentStops {
  margin-top: 16px !important;
}

.unhealthy {
  position: absolute;
  font-size: 32px;
  top: 120px;
  left: 70px;
  width: 450px;
  font-weight: 800;
  color: var(--liftstatus-yellow);
  text-align: center;
}


.disconnected {
  position: absolute;
  font-size: 32px;
  top: 220px;
  left: 70px;
  width: 450px;
  font-weight: 800;
  color: var(--liftstatus-red);
  text-align: center;
}

.scene {
  position: relative;
  margin-top: 16px;
  margin-bottom: 26px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.currentStop {
  top: 10px;
  left: 10px;
}

.lastStop {
  left: 10px;
  bottom: 90px;
}
.stopsGraphContainer {
  margin: 10px 0 40px 0;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}
