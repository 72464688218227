@media print {
  table.page {
    padding:20px!important;

    thead {
      break-inside: avoid!important;
      display: table-header-group!important;
    };
  }
  .content {
    width: auto!important;
    > div {
      page-break-inside: avoid;
    }
    .periods {
      width: auto!important;
    }
  }
  .printLabel {
    color: lightgray;
  }
}

.printSelectedOrganisation {
  border: 1px solid lightgray;
  display: flex;
  text-align: left;
  padding: 12px;
  font-size: 1.2rem;
  > div {
    flex: 1;
  }
}
.printLabel {
  color: lightgray;
}

table.page {
  margin: 0;
  padding-bottom: 26px;
  width: 800px;
  font-size: 0.8rem;
  thead {
    display: none;
    tr {
      th {
        a {
          text-decoration: none;
          font-size: 1.1rem;
          &:link {
            color: black;
          }
        }
        svg {
          vertical-align: middle;
        }
      }
    }
    .printHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      padding-bottom: 12px;
      padding-top: 6px;
    }
  }
  tbody {
    & > div {
      page-break-inside: initial;
      padding-bottom: 5px;

      &:nth-child(1) {
        page-break-after: always;
      }
    }
  }
}

.ednlLogo {
  max-width: 250px;
}