
@media print {
  .root {
    display: none;
  }
}

.root {
  width: 100%;
  padding-bottom: 18px;
}

.periods {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.reverse {
  flex-direction: row-reverse;
}

.periodsBtn {
  height: 55px;
  width: 250px;
  span {
    margin-left:6px;
  }
}

.rangeColumn {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin: 6px 0 0 0;
    font-size: 12px;
    color: var(--liftstatus-grey);
    text-align: center;
    width: 200px;
  }

  .dateRangeBtn {
    height: 55px;
    width: 190px;
    font-size: 18px;
    align-self: flex-end;
  }
}
