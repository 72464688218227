.cardContainer {
  margin-bottom: 20px;

  p {
    color: var(--liftstatus-grey);
    margin: 0;
    align-self: center;
    min-width: 480px;
    display: flex;
    justify-content: space-between;
  }

  .visitReason {
    width: 100%;
    margin-bottom: 22px;
    font-weight: 500;
    color: var(--liftstatus-grey);
  }
}
