@media print {
  table.page {
    thead.printHeader {
      break-inside: avoid!important;
      display: table-header-group!important;
    };
  }
  .content {
    width: auto!important;
    > div {
      page-break-inside: avoid;
    }
    .periods {
      width: auto!important;
    }
    .fullWidth {
      width: auto!important;
    }
    h4:not(.subHeading) {
      display: none!important;
    }
    h4.subHeading {
      font-size: 2rem;
      border-bottom: 1px solid #70808f;
    }
    .column {
      flex-basis: auto!important;
      flex: 1;
    }
  }



  .printSelectedLift {
    border: 1px solid lightgray;
    width: 96%;
    min-height: 50px;
    display: flex;
    text-align: left;
    padding: 12px;
    font-size: 1.2rem;
    > div {
      flex: 1;
    }
  }
  .printLabel {
    color: lightgray;
  }
}

.root {
  display: flex;
}

.backButton {
  width: 64px;
}

h3 {
  font-weight: 500;
  color: var(--liftstatus-grey);
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.notFound {
  grid-column: 1/5;
  text-align: center;
  font-weight: 500;
  color: #f3776d;
}

table.page {
  margin: 0;
  width: 650px;
  font-size: 0.8rem;
  thead.printHeader {
    display: none;
    tr:first-child {
      th {
        &:first-child {
          text-align: left;
          width: 450px;
        }
        &:last-child {
          text-align: left;
          font-size: 1.1rem;
          width: 200px;
          a {
            text-decoration: none;
            &:link {
              color: black;
            }
          }
          svg {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.ednlLogo {
  max-width: 250px;
}

.content {
  width: 100%;
  display: block;
  padding: 16px 16px 0 0;
  page-break-inside: initial;
  padding-bottom: 18px;

  .titleBar {
    display: flex;
    justify-content: space-between;
    min-height: 50px;

    .title {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      color: var(--liftstatus-primary-text-color);
      margin-bottom: -16px;
    }
  }

  .periods {
    justify-content: space-between;
    display: flex;
    width: 100%;
    max-width: 1220px;
  }

  .fullWidth {
    padding-top: 16px;
    grid-column: 1/5;
    width: 100%;
    max-width: 1220px;
    height: 100%;
    overflow: visible;
    padding-bottom: 2rem;
  }

  h4 {
    margin: 0;
    font-weight: normal;
  }
  .column {
    flex-basis: 33.33%;
  }
  .subHeading {
    color: var(--liftstatus-grey);
  }

  .mainDetails {
    padding: 2px 20px;
  }

  .loading {
    grid-column: 1/6;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.progressIndicator {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fixedHeader {
  position: relative;
}