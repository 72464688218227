@media print {
  .root {
    height: 100px!important;
    display: block!important;
  }
  .tooltip {
    display: none!important;
  }

  .content {
    .title {
      color: #70808f!important;
      font-size: 1.2rem!important;
      border-bottom: 1px solid #70808f;
    }
    h1 {
      text-align: left!important;
    }
  }
}

.root {
  display: flex;
  width: 215px;
  height: 130px;

  .content {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;

    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 12px;
      color: var(--liftstatus-primary-color);
      line-height: 1.3rem;
      width: 100%;
      .titleText {
        white-space: nowrap;
        overflow: hidden;
      }
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    h1 {
      text-align: center;
      padding: 6px;
      margin: 0;
      font-size: 36px;
      font-weight: 400;
      line-height: 1.2;
    }

    .btn {
      align-self: flex-end;
      span {
        color: var(--liftstatus-primary-color) !important;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
