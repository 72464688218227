.tableContainer {
  margin-bottom: 36px;

  .table {
    min-width: 650px;
  }

  .summaryData {
    display: grid;
    grid-template: 1fr 1fr / 350px 350px;
    padding: 16px;

    p:nth-child(2) {
      font-weight: 600;
    }
  }
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}
