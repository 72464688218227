$repair: #f8aa27;
$maintenance: #642bb6;
$modernization: #795548;
$approval: #4caf50;
$inspection: #0099ff;
$unavailable: rgba(255, 0, 0, 0.4);
$failure: #e64a19;
$other: #b2d6cd;
$disconnected: rgba(145, 145, 145, 0.6);



.blueline {
  z-index: 1;
  fill: none;
  stroke: #0099ff;
  stroke-width: 3px;
}

.redline {
  z-index: 1;
  fill: none;
  stroke: #ff0000;
  stroke-width: 3px;
}

.dottedline {
  z-index: 1;
  fill: none;
  stroke: #d8d8d8;
  stroke-width: 3px;
}

.zero-line {
  fill: none;
  stroke: #86b29c;
  stroke-width: 2px;
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}

.y-axis .domain {
  stroke: white;
}

.x-axis .domain {
  stroke: #eeeeee;
  stroke-width: 3px;
}

.x-axis .tick {
  text-transform: uppercase;
}

.x-axis .tick line {
  stroke: #eeeeee;
  stroke-width: 3px;
}

.unvailable-group {
  cursor: pointer;
  z-index: 5;
  .unvailable-time {
    fill: rgba(255, 0, 0, 0.4);
  }
  .unvailable-hitarea {
    fill: transparent;
  }
  &.selected {
    .unvailable-hitarea {
      fill: white;
      stroke: rgba(1, 1, 1, 0.2);
      stroke-width: 2;
    }
  }
}

.unhealthy-group {
  cursor: pointer;
  z-index: 5;
  .unhealthy-time {
    fill: rgba(145, 145, 145, 0.6);
  }
  .unhealthy-hitarea {
    fill: transparent;
  }
  &.selected {
    .unhealthy-hitarea {
      fill: white;
      stroke: rgba(53, 53, 53, 0.4);
      stroke-width: 2;
    }
  }
}

.event-group {
  cursor: pointer;
  .event-hitarea {
    fill: transparent;
  }
  &.selected {
    .event-hitarea {
      fill: white;
      stroke: rgba(1, 1, 1, 0.2);
      stroke-width: 2;
    }
  }
  .event {
    stroke: #a3a3a3;
    stroke-width: 3px;
    fill: white;
    &.repair {
      stroke: $repair;
    }
    &.maintenance {
      stroke: $maintenance;
    }
    &.modernization {
      stroke: $modernization;
    }
    &.approval {
      stroke: $approval;
    }
    &.inspection {
      stroke: $inspection;
    }
    &.failure {
      stroke: $failure;
    }
    &.other {
      stroke: $other;
    }
    &.unavailable {
      color: $unavailable;
    }
    &.disconnected {
      color: $disconnected;
    }
  }
}

ul.legenda {
  font-size: 12px;
  list-style: none;

  li {
    float: left;
    padding-right: 1rem;
    color: var(--liftstatus-grey);
    &::before {
      content: "•";
      color: gray;
      font-size: 3rem;
      line-height: 0;
      vertical-align: middle;
      padding-right: 0.2rem;
    }
    &.repair::before {
      color: $repair;
    }
    &.maintenance::before {
      color: $maintenance;
    }
    &.modernization::before {
      color: $modernization;
    }
    &.approval::before {
      color: $approval;
    }
    &.inspection::before {
      color: $inspection;
    }
    &.failure::before {
      color: $failure;
    }
    &.unavailable::before {
      color: $unavailable;
    }
    &.disconnected::before {
      color: $disconnected;
    }
  }
}

.square {
  margin-top: 16px;
  display: flex;
  width: 100%;

  ul {
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        content: "■";
        font-size: 1.8rem;
        line-height: 0.5;
        vertical-align: baseline;
        padding-bottom: 0.38rem;
        padding-right: 0.3rem;
      }
  }

  }
}
