.root {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    width: 100%;
    grid-gap: 16px;

    .leftColumn {
        grid-column: 1/2;
      }

      .rightColumn {
        grid-column: 2/3;
      }

      h3 {
        font-weight: 500;
        color: var(--liftstatus-grey);
        font-size: 14px;
        margin-top: 16px;
      }

      p {
        display: flex;
        justify-content:space-between;
        font-size: 12px;
        color: var(--liftstatus-grey);
        min-width: 400px;
      }


      .divideText {
        display: flex;
        min-width: 400px;
      }

      .ucfirst::first-letter {
        text-transform: uppercase;
      }
}

