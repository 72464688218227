.root {
  display: flex;
}

.content {
  padding: 16px;
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
}

.card {
  margin-bottom: 16px;
}