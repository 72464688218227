.liftData {
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  padding: 10px;
  background-color: #f3f3f3;
  color: var(--liftstatus-primary-color-dark);
  font-size: 16px;
}

.liftLocation {
  color: var(--liftstatus-grey);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.liftLocationIcon {
  color: var(--liftstatus-primary-color-dark);
  min-width: 26px;
  min-height: 26px;
  margin-right: 6px;
}
