.progressIndicator {
  width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.marginBottom {
  margin-bottom: 16px;
}
