@media print {
  .content {
    grid-row-gap: 0!important;
  }

  .root {
    display: block!important;
  }

}

.root {
  display: flex;
}

.content {
  height: 100%;
  display: grid;
  grid-template: auto/ 1fr;
  // grid-row-gap: 16px;
  padding: 16px;
  background-color: var(--ion-color-primary);
  max-width: 65%;

  .titleBar {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: var(--liftstatus-primary-text-color);
    margin-bottom: -16px;
  }
}

.noorganisation {
  grid-area: 2/1/2/5;
  color: gray;
}
