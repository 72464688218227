.wrapper {
  padding: 16px;
  margin-bottom: 16px;
  .btnWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .card {
    width: 100%;
    .cardContainer {
      margin-bottom: 6px;
      padding-top: 6px;
      padding-bottom: 6px;

      border-bottom: 1px solid rgb(223, 223, 223);
      display: flex;
      justify-content: space-between;
      color: var(--liftstatus-grey);
      // padding-left: 30px;

      p {
        margin: 0;
        align-self: center;
        padding-right: 16px;
        font-size: 12px;

        display: flex;
        justify-content: space-between;

        width: 100%;
      }

      h4 {
        margin: 0;
        align-self: center;
        font-weight: 500;
        margin-bottom: 4px;
        color: black;
      }

      .dates {
        color: var(--liftstatus-dark-grey);
        font-weight: normal;
      }

      span {
        text-align: right;
        color: black;
      }

      .remarks {
        padding-left: 12px;
        margin: 8px 0 12px;
      }

      .remarksTitle {
        font-weight: bold;
      }
    }
  }
}
