.progressIndicator {
  justify-self: center;
}

.card {
  padding: 16px;
}

.card:hover {
  background-color: whitesmoke;
}

.cardContent {
  font-size: 12px;
}

h3 {
  font-weight: 500;
  color: var(--liftstatus-grey);
  font-size: 14px;
  margin-top: 0;
}