@media print {
  .root {
    display: none;
  }
}
.tableContainer {
  margin-bottom: 16px;
}

.paginationButtonContainer {
  display: flex;
}

.checkMarkLabel {
  font-size: 16px !important;
}

.checkMarks {
  display: grid !important;
  grid-template: 1fr / 1fr 1fr 1fr 1fr !important;
  margin-bottom: 16px;
}


.firstCell {
  align-items: center;
  padding: 0px;
  position: relative;

  .icon {
    position: absolute;
    left: 1px;
    bottom: 16px;
  }

  .description {
    margin-left: 16px;
    margin-bottom: 16px;
  }
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}
