.root {
  margin-bottom: 2px;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .leftColumn {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 12px;
    color: grey;
  }

  Button {
    align-self: flex-end;
    color: var(--liftstatus-primary-color-dark);
  }
}
