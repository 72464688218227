.root {
  padding: 16px;
}

.periodSelectorContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.eventCheckboxesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);

  .MuiCheckbox-root {
    padding: 4px;
  }
}

.label {
  span {
    text-align: left !important;
    font-size: 13px;
  }
}

