@media print {
  .card, .cardContainer {
    display: block!important;
  }
}

.card {
  grid-column: 1/5;
  cursor: pointer;

  .cardContainer {
    display: grid;
    grid-template: auto / 1fr 100%;
    grid-column-gap: 6px;

    .lifticon {
      grid-column: 1/2;
      grid-row: 1/3;
      align-self: center;
      justify-self: center;
      color: var(--liftstatus-grey);
    }

    p {
      color: var(--liftstatus-grey);
      margin: 0;
      grid-column: 2/3;
      align-self: center;
      padding-right: 16px;
      width: 100%;
    }

    h4 {
      margin: 0;
      grid-column: 2/3;
      align-self: center;
      font-weight: 500;
    }
  }
}

.hover {
  background-color: whitesmoke;
}
