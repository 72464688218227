.root {
    display: flex;

  
  .wrapper {
    padding: 16px;
    margin-bottom: 160px;
    .checkBoxesWrapper {
      display: grid;
      grid-template-columns:  1fr 1fr;
      h3 {
        font-style: bold;
        font-size: 18px;
      }
      .eventCheckboxesContainer {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
      }
    }

    .organisations {
      margin-top: 26px;

      .ogNames {
        display: grid;
        grid-template: auto / 1fr 1fr 1fr;
        grid-gap: 16px;
        width: 100%;
        margin-top: 16px;
        .ogRow {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 6px;
          font-weight: bold;
          color: black;

          button {
            background-color: inherit;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  .auto {
    margin-top: 16px;
  }
}