@media print {
  .root {
    .card {
      .cardContainer {
        h4 {
          display: block!important;
        }
      }
    }
  }
}

.root {
  margin-bottom: 16px;

  margin-top: 6px;
  .progressIndicator {
    justify-self: center;
  }

  h3 {
    color:rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }

  .card {
    margin-bottom: 10px;

    .cardContainer {
      p {
        color: var(--liftstatus-grey);
        margin: 0;
        align-self: center;
        padding-right: 16px;
      }

      p.subTitle {
        margin-bottom: 8px;
      }

      h4 {
        margin: 0;
        align-self: center;
        font-weight: 500;
      }

      .remarks {
        padding-left: 12px;
      }

      .remarksTitle {
        font-weight: bold;
      }

    }
  }

  .card:hover {
    background-color: whitesmoke;
  }

  .cardContent {
    padding: 16px;

    h3 {
      margin-bottom: 12px;
    }
  }
}