.root {
  display: flex;
}

.content {
  overflow: auto;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px;
  max-width: 800px;

  .search {
    display: flex;
    padding: 2px 16px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-column: 1/5;

    .input {
      margin-left: 16px;
      width: 100%;
    }

    .advancedSearchBtn {
      font-size: 12px;
    }

    .icon-button {
      padding: 10px;
    }
  }

  .title {
    grid-column: 1/5;
    width: 100%;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      color: var(--liftstatus-grey);
    }
  }
}

.card {
  margin: 6px 0px 6px 0px;

  h4 {
    margin: 0;
    font-weight: 500;
  }

  p {
    margin: 0;
    color: var(--liftstatus-grey);
    width: 100%;
  }

  .multipleLifts {
    color: red;
  }
}

.card:hover {
  background-color: whitesmoke;
}

.formControl {
  width: 189px;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 6px;
}

.progressIndicator {
  width: 100%;
  justify-self: center;
  margin-top: 16px;
}
