
.lastChange {
  color: var(--liftstatus-grey);
}

.badge {
  position: absolute;
  right: 10;
  top: 5;
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 1px 5px;
}


.wrapper {
  margin: 16px;
  width: 100%;
}