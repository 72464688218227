h2 {
    font-family: 'Roboto';
    font-weight: 100;
    span {
        color: var(--liftstatus-primary-color-dark);
    }
}

button.md-button {
    margin-top: 18px;
    width: 50px;
}

md-datepicker {
    margin-top: 12px;
    margin-right: 15px;
}

.miniTimeDisplay,
.mainTimeDisplay {
    width: 300px;
    height: 20px;
    background-color: #CFD8DC;
    position: absolute;
    padding: 4px 8px;
    color: #333;
    border-radius: 5px;
    font-size: 13px;
    text-align: center;
    line-height: 13px;
}

.chart {
  :global {
    .mini {
      text {
          font: 9px sans-serif;
      }

      .mini-time-indicator {
          stroke: var(--liftstatus-grey);
          stroke-width: 1.5;
      }
    }

    .mainItem.value-D,
    .mainItem.value-U {
        stroke: transparent;
    }

    .paginate button {
        min-width: 40px;
        width: 40px;
    }

    .future {
        stroke: var(--liftstatus-dark-grey);
        fill: #ddd;
    }

    .past:not(.is-empty) {
        stroke: var(--liftstatus-primary-color-dark);
        fill: var(--liftstatus-primary-color);
    }

    g.period rect.number.even {
      fill: var(--liftstatus-primary-color);
      stroke: var(--liftstatus-primary-color);
    }
    g.period rect.number.odd {
      fill: #CFD8DC;
      stroke: #CFD8DC;
    }
    rect.past.is-empty {
      fill: transparent;
      stroke: transparent;
    }

    .past.value-O,
    .past.value-C {
        fill: #b4cfc2;
    }

    .past.value-X {
        fill: #809088;
    }

    .main {
        text {
            font: 10px sans-serif;
        }


        .main-time-indicator {
            stroke: gray;
            stroke-width: 1.5;
        }

        g.period rect.active {
            fill: var(--liftstatus-red) !important;
            stroke: var(--liftstatus-red) !important;
        }

        text, image {
            user-select: none;
            pointer-events: none;
        }
    }

    .month text {
        text-anchor: start;
    }

    .todayLine {
        stroke: blue;
        stroke-width: 1.5;
    }

    .timeIndicatorLine {
        stroke: black;
        stroke-width: 1;
    }

    .axis line, .axis path {
        stroke: black;
    }

    .miniItem {
        stroke-width: 6;
    }

    .mainItemPurple {
        fill: purple!important;
        stroke: black!important;
    }

    .brush .extent {
        stroke: var(--liftstatus-grey);
        fill: blue;
        fill-opacity: .165;
    }
  }
}


