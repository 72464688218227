body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#__react-alert__ div {
  text-transform: none!important;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Digital-7 Mono";
  src: url(./Assets/Fonts/digital-7.eot?) format("eot"),
       url(./Assets/Fonts/digital-7.woff) format("woff"),
       url(./Assets/Fonts/digital-7.ttf) format("truetype"),
       url(./Assets/Fonts/digital-7.svg) format("svg");
}

@media print{
  html, body {
    font-size: 12px;
    -webkit-print-color-adjust: exact;
  }
  div[role=presentation] {
    display: none !important;
  }
  @page {
    size: A4 portrait;
  }
  ::-webkit-scrollbar {
      display: none;
  }

  .MuiPaper-elevation1 {
    box-shadow: none!important;
  }
  .MuiAccordionSummary-expandIcon {
    display: none!important;
  }
  .MuiCircularProgress-root {
    display: none!important;
  }

  .chartjs-size-monitor {
    width: 750px!important;
  }
}

