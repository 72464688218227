.root {
  display: flex;
}

@media print {
  .content {
    height: auto!important;
  }
  .liftDetails {
    width: inherit!important;
    height: inherit!important;
  }
  .liftsList {
    display: block;
  }
  h3 {
    color: black!important;
  }
}

.content {
  overflow: auto;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 0;
  padding-left: 16px;

  .liftsList {
    display: grid;
    grid-template: auto / 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
    padding-top: 16px;
    max-width: 800px;
  }

  .liftDetails {
    width: 100%;
    height: 100%;
  }

  .search {
    display: flex;
    padding: 2px 16px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-column: 1/5;

    .input {
      margin-left: 16px;
      width: 100%;
    }

    .advancedSearchBtn {
      font-size: 12px;
    }

    .icon-button {
      padding: 10px;
    }
  }
  .advSearch {
    padding: 24px;
    grid-column: 1/5;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .advSearchField {
      margin: 6px;
    }

    .advBottomRow {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      Button {
        margin: 0px 8px 0px 8px;
      }
    }
  }

  .title {
    grid-column: 1/5;
    width: 100%;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      color: var(--liftstatus-grey);
    }
  }
}

.periods {
  grid-column: 1/5;
}

.formControl {
  width: 189px;
}

.pagination {
  grid-column: 1/5;
  justify-self: center;
  padding: 6px;
}

.progressIndicator {
  grid-column: 1/5;
  justify-self: center;
}
