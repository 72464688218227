.wrapper {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  width: 100%;

  .leftColumn {
    grid-column: 1/2;
  }

  .rightColumn {
    grid-column: 2/3;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #f5f5f5;
    margin: 1em 0;
    padding: 0;
  }

  p {
    display: flex;
    width: 250px;
    justify-content: space-between;
    font-size: 12px;
    color: var(--liftstatus-grey);
  }

  span {
    color: black;
    text-align: right;
  }
}
