.root {
  display: flex;
  min-height: 100vh;
}
.toolbar {
  padding-right: 24; // keep right padding when drawer closed
}
.toolbarIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  min-height: 56px;
}

.menuButton {
  margin-right: 0;
  color: white;
}

.menuButtonHidden {
  display: none;
}

.paper {
  padding: 16px;
  display: flex;
  overflow: auto;
  flex-direction: column;
}
.toolbar {
  min-height: 64px;
}
.ogSelect {
  margin-top: 10px;
  margin-bottom: 16px;
}

.header {
  display: flex;
  width: 100%;
  padding-left: 16px;
  background-color: #86b29c;
  justify-content: space-between;
}

.headerTitle {
  font-weight: 400;
  letter-spacing: 2.5px;
  margin-right: 16px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 16px;
}
.menuItems {
  color: var(--liftstatus-grey);
  font-size: 12px;
}

.white {
  color: white;
}
.icon {
  height: 20px;
  color: var(--liftstatus-grey);
}

.link {
  text-decoration: none;
  color: #70808f;
}

.itemText {
  font-size: 14px;
}

.elevatorSelectorBtn {
  width: 100%;
}

.selectedRoute {
  background-color: #f3f3f3 !important;
}

div.account {
  padding-bottom: 1rem;
  position: fixed;
  bottom: 0;
  width: 220px;
  overflow: hidden;
}

.accountName {
  font-size: 14px;
  &::first-letter {
    text-transform: uppercase;
  }
}

.toolbarClosed {
  div.account {
    width: 56px;
  }
}

@media print {
  .root {
      display: none !important;
  }
}
