/** Global CSS Variables **/
:root {
 --liftstatus-primary-color-dark: #3e7158;
 --liftstatus-primary-color: #86B29C;
 --liftstatus-red: #F3776D;
 --liftstatus-yellow: #F3C46D;
 --liftstatus-green: #86B29C;
 --liftstatus-grey:  #697179;
 --liftstatus-dark-grey: #34373a;
 --liftstatus-primary-text-color: rgba(0, 0, 0, 0.87);
}




