.root {
  display: flex;
  .content {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: grid;
    padding: 16px;
    grid-template: auto / 215px 215px 215px 215px;
    grid-gap: 16px;

    .periods {
      grid-column: 1/5;
    }

    .chart {
      grid-column: 1/5;
    }
  }
}
