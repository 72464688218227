.container {
  position: absolute;
  text-align: center;
  width: 115px;
  z-index: 100;
  margin-bottom: 16px;
}

.label {
  text-transform: uppercase;
  font-family: "Verdana", "Geneva", "Tahoma", "sans-serif";
  font-size: 0.6rem;
}

.floorIndicator {
  font-family: "Digital-7 Mono";
  font-size: 7rem;
  line-height: 7rem;
  margin: 0;
  width: 107px;
  position: absolute;
  left: 0;
  top: 14px;
  text-align: right;
}

.floorCurrentBackground {
  color: #f5f5f5;
}

.floorCurrentForeground {
  color: var(--liftstatus-green);
}
