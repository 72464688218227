.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
  }

  .graphLegendItem {
    margin-top: 16px;
    color: red !important;
  }