.liftDetailsHistory {
  display: block;
  width: 100%;


  .historyTabs {
    margin-bottom: 16px;
  }

  .datePicker {
    width: 150px;
    justify-self: end;
    margin-right: 46px;

    .timeSelector {
      width: 150px;
      margin-bottom: 26px;
    }
  }
}


.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
