h4 {
  margin: 0;
  font-weight: normal;
}
.column {
  flex-basis: 33.33%;
}
.subHeading {
  color: var(--liftstatus-grey);
}

.command {
  flex-direction: column;
}
.MuiPaper-root {
  border-radius: 0 !important;
}
