.chart {
  min-height: 200px;
  min-width: 100%;

  .path {
    fill: none;
    stroke: #84b28d;
    stroke-width: 3px;
  }

  .line {
    stroke: #ddd;
  }

  .stop {
    fill: #84b28d;
    stroke: none;
  }

  .fake {
    fill: transparent;
    stroke: none;
  }

  .passed {
    fill: #fff;
    stroke: #84b28d;
    stroke-width: 1px;
  }

  .overlay {
    fill: none;
    stroke: none;
    pointer-events: all;
  }

  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
  }

  .focusLine {
    fill: none;
    stroke: steelblue;
    stroke-width: 1px;
  }


  .focusCircle {
    fill: #406452;
  }

  .focus_text_container {
    rect {
      fill: rgba(0, 0, 0, 0.1);
    }
    text {
      fill: black;
    }
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: grey;
    stroke-width: 1;
    shape-rendering: crispEdges;
  }

  
  &.disconnected {
    svg {
        display: none;
    }

    &::after {
        content: "Disconnected";
        position: absolute;
        width: 100%;
        padding-top: 50px;
        box-sizing: border-box;
        height: 100%;
        font-size: 2.5rem;
        color: #757575;
        text-transform: uppercase;
        left: 0;
        top: 0;
        text-align: center;
        border: 1px solid #757575;
        background-color: rgba( 255, 255, 255, 1 );
    }
}
}


.loading {
  display: none;
}