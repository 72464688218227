.card {
  grid-column: 1/5;

  .titleBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }

  p {
    margin: 0;
    color: var(--liftstatus-grey);
  }

  .actions {
    padding: 0;
    padding-top: 16px;
  }

  .icon {
    color: var(--liftstatus-grey);
  }
}

.card:hover {
  background-color: whitesmoke;
}

p {
  margin: 0;
}

.timers {
  display: flex;
  flex-direction: column;
}