.detailsExpansion {
  display: grid;
  width: 100%;
  grid-template: auto / auto;
  grid-column-gap: 16px;
  max-width: 1220px;

  .leftColumn {
    grid-column: 1/2;
  }
  .rightColumn {
    grid-column: 2/3;
  }

  .singleColumn {
    grid-column: 1/3;
  }

  p {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: var(--liftstatus-grey);
    min-width: 400px;
  }
}

@media print {
  .detailsExpansion {
    width: auto!important;
    p {
      min-width: 300px;
    }
  }
}
