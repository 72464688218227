@media print {
  .wrapper {
    width: auto!important;
    .twoRow {
      grid-template: 1fr / repeat(3, 215px)!important;
    }

    .threeRow {
      grid-template: 1fr / repeat(3, 215px)!important;
    }

    .fourRow {
      grid-template: 1fr / repeat(3, 215px)!important;
    }

    .fiveRow {
      grid-template: 1fr / repeat(3, 215px)!important;
    }
  }
}

.wrapper {
  width: 100%;
  padding: 18px 0;
  // The rows are Grids;
  // so we position the row first and then create it's own
  // grid to position the cards inside of it.
  //
  .twoRow {
    margin-top: 16px;
    display: grid;
    grid-template: 1fr / repeat(2, 215px);
    grid-gap: 16px;
  }

  .threeRow {
    margin-top: 16px;
    display: grid;
    grid-template: 1fr / repeat(3, 215px);
    grid-gap: 16px;
  }

  .fourRow {
    margin-top: 16px;
    display: grid;
    grid-template: 1fr / repeat(4, 215px);
    grid-gap: 16px;
  }

  .fiveRow {
    margin-top: 16px;
    display: grid;
    grid-template: 1fr / repeat(5, 215px);
    grid-gap: 16px;
  }
}
