.root {
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 18px;
    color: var(--liftstatus-primary-text-color);
    align-self: flex-start;
  }

  .subtitle {
    font-size: 12px;
    color: var(--liftstatus-grey);
  }

  Button {
    align-self: flex-end;
    color: var(--liftstatus-primary-color);

    span {
      color: var(--liftstatus-primary-color);
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
