.root {
  line-height: 0.4rem;

  svg {
    font-size: 1.1rem;
  }
}


.statusOk {
  color:var(--liftstatus-primary-color);
}

.statusNotOk {
  color: red;
}

.statusOn {
  color:var(--liftstatus-yellow);
}

.statusOff {
  color:var( --liftstatus-dark-grey);
}