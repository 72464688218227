.container {
  position: relative;
  padding: 6px 20px 6px 1px;
  display: flex;
  flex-direction: column;
}

.text {
  padding-left: 12px;
  font-size: 0.85rem!important;
}

.badge {
  position: absolute;
  right: 12px;
  top: 5px;
  border-radius: 5px;
  padding: 3px 5px;
  font-weight: 800;
  font-size: 0.75rem!important;
}

.level {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
  position: absolute;
  right: 16px;
  top: 30px;
}

.value {
  font-size: 0.9rem!important;
}

.date {
  font-size: 0.75rem!important;
  font-weight: normal!important;
  padding-left: 20px;
}

.bottomLine {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f5f5f5;
  padding: 0;
  margin: 0.2rem 0 0.5rem 0 !important;
}

.impact {
  border-bottom: 1px solid white;
  .text {
    color: white;
  }

  background-color: #ec6c40;

}

.nonImpact {

  & hr {
    border-top-color: #f3c46d !important;
  }

  .text {
    color: white;
  }

  background-color: #f3c46d;
  border-bottom: 1px solid white;

}

.textDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  .icon {
    font-size: 24px;
  }
}
