.root {
  display: flex;
}

.content {
  overflow: auto;
  display: grid;
  height: 100%;
  width: 100%;
  padding: 16px;
  grid-template: auto / 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  max-width: 800px;
}



.progressIndicator {
  grid-column: 1/5;
  justify-self: center;
}

.title {
  width: 100%;
  grid-column: 1/5;
  text-align: center;
}

.noAlerts {
  grid-column: 1/5;
}
