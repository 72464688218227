.login {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: none;
}

.leftDiv {
  padding: 16px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #86b29c;
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 56px;
  }

  h3 {
    margin-top: 6px;
    color: grey;
  }

  .inputsWrapper {
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    width: 260px;

    .username {
      padding-bottom: 1rem;
    }
  }

  .versionNumber {
    bottom: 5px;
    position: absolute;
    left: 175px;
    color: grey;
    font-size: 12px;
  }

  .resetPassword {
    font-size: 11px;
    text-decoration: underline;
    color: grey;
    margin-top: 16px;
    cursor: pointer;
  }

  button {
    margin-top: 18px;
  }
}

.rightDiv {
  width: 100%;
  contain: content;
  img {
    height: 100%;
    width: 100%;
  }
}
