.root {
  background-image: url("../../Assets/bwbuilding.jpg");
}

.inputInvalid {
  color: red;
  font-size: 10px;
  padding-top: 6px;
}

.recovery {
  width: 100vw;
  height: 100vh;
  background-image: url("../../Assets/bwbuilding.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .card {
    margin-top: 15%;
    width: 400px;
    height: 420px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 160px;
    }

    .button {
      width: 320px;
      margin-top: 26px;
    }

    .inputsWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .input {
        width: 320px;
        min-height: 64px;
      }
    }
  }
}
